import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Image from 'mui-image';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const theme = createTheme({});
const themeLanguage = createTheme({});

function TopBar({ language }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [openLanguage, setOpenLanguage] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    try {
      if (e.currentTarget.localName !== 'ul') {
        const menu = document.getElementById('simple-menu').children[2];
        const menuBoundary = {
          left: menu.offsetLeft,
          top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
          right: menu.offsetLeft + menu.offsetHeight,
          bottom: menu.offsetTop + menu.offsetHeight,
        };
        if (
          e.clientX >= menuBoundary.left &&
          e.clientX <= menuBoundary.right &&
          e.clientY <= menuBoundary.bottom &&
          e.clientY >= menuBoundary.top
        ) {
          return;
        }
      }

      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
    setOpenLanguage(true);
  };

  const handleCloseLanguage = (e) => {
    try {
      if (e.currentTarget.localName !== 'ul') {
        const menu = document.getElementById('simple-menu2').children[2];
        const menuBoundary = {
          left: menu.offsetLeft,
          top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
          right: menu.offsetLeft + menu.offsetHeight,
          bottom: menu.offsetTop + menu.offsetHeight,
        };
        if (
          e.clientX >= menuBoundary.left &&
          e.clientX <= menuBoundary.right &&
          e.clientY <= menuBoundary.bottom &&
          e.clientY >= menuBoundary.top
        ) {
          return;
        }
      }

      setOpenLanguage(false);
    } catch (error) {
      console.log(error);
    }
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  themeLanguage.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleCloseLanguage(e);
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingX={12}
        paddingY={6}
      >
        <Image src="/images/terratrade_logo.png" width={'200px'}></Image>

        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ marginTop: 2 }}
        >
          <Button
            sx={{ marginX: { sm: 3 } }}
            component={Link}
            to={language ? `/${language}/` : '/'}
          >
            {language ? 'Anasayfa' : 'Home'}
          </Button>
          <Button
            sx={{ marginX: { sm: 3 } }}
            component={Link}
            to={language ? `/${language}/automotive` : '/automotive'}
          >
            {language ? 'Otomotiv' : 'Automotive'}
          </Button>
          <Button
            sx={{ marginX: { sm: 3 } }}
            component={Link}
            to={language ? `/${language}/food` : '/food'}
          >
            {language ? 'Gıda' : 'Food'}
          </Button>
          <Button
            sx={{ marginX: { sm: 3 } }}
            id="menubutton1"
            aria-owns={open ? 'simple-menu' : null}
            aria-haspopup="true"
            onMouseOver={handleOpen}
            onMouseLeave={handleClose}
            style={{ zIndex: 1301 }}
          >
            {language ? 'Diger' : 'Other'}
          </Button>

          <Button
            sx={{ marginX: { sm: 3 } }}
            id="menubutton2"
            aria-owns={open ? 'simple-menu2' : null}
            aria-haspopup="true"
            onMouseOver={handleOpenLanguage}
            onMouseLeave={handleCloseLanguage}
            style={{ zIndex: 1301 }}
          >
            <Image
              duration={0}
              src={language ? '/images/tr.png' : '/images/eng.png'}
              width={'15px'}
              style={{ paddingRight: '20px' }}
            ></Image>
            {language ? 'Turkce' : 'English'}
          </Button>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{ onMouseLeave: handleClose }}
        disableAutoFocusItem={true}
      >
        <MenuItem
          onClick={() => {
            setOpen(false);
          }}
          component={Link}
          to={language ? `/${language}/about` : '/about'}
        >
          {language ? 'Hakkımızda' : 'About Us'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
          }}
          component={Link}
          to={language ? `/${language}/contact` : '/contact'}
        >
          {language ? 'İletişim' : 'Contact'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
          }}
          component={Link}
          to={language ? `/${language}/privacy-policy` : '/privacy-policy'}
        >
          {language ? 'KVKK' : 'Privacy Policy'}
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu2"
        anchorEl={anchorElLanguage}
        open={openLanguage}
        MenuListProps={{ onMouseLeave: handleCloseLanguage }}
        disableAutoFocusItem={true}
      >
        <MenuItem
          onClick={() => {
            setOpenLanguage(false);
          }}
          component={Link}
          to={language ? '/' : '/tr'}
        >
          <Image
            duration={0}
            src={language ? '/images/eng.png' : '/images/tr.png'}
            width={'15px'}
            style={{ paddingRight: '20px' }}
          ></Image>
          {language ? 'English' : 'Turkce'}
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}

export default TopBar;
