import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';

function PrivacyPolicy() {
  let { lang } = useParams();
  const theme = useTheme();
  const smsize = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    console.log(lang);
  }, [lang]);
  //columnSpacing={10} rowSpacing={10} paddingX={10} marginY={-3}

  return (
    <div>
      <TopBar language={lang}></TopBar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 4,
          mb: 8,
          width: { xs: '80%' },
          mx: 'auto',
        }}
      >
        {lang ? (
          <>
            <Typography
              variant={smsize ? 'h3' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              KVKK
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              6698 Sayılı KVK Kanununun 3. Maddesinde;
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 0 }}
              color={'black'}
            >
              Açık rıza, kanunda “belirli bir konuya ilişkin, bilgilendirilmeye
              dayanan ve özgür iradeyle açıklanan rıza” şeklinde tanımlanmıştır.
              Buna göre açık rızanın üç unsuru vardır:
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              • Belirli bir konuya ilişkin olması
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              • Bilgilendirilmeye dayanması
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              TERRA TRADE LTD. ŞTİ.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AÇIK RIZA BEYANI
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              TERRA TRADE LTD. ŞTİ. (‘‘Şirket’’) tarafından, 6698 Sayılı Kişisel
              Verilerin Korunması Kanunu’nun (“KVK Kanunu”) ilgili hükümlerine
              uygun olarak bilginize sunulan Kişisel Verilerin Korunması Kanunu
              Kapsamında Genel Aydınlatma Metni ve Şirket Kişisel Verilerin
              Korunması ve İşlenmesi Yönetmeliği çerçevesinde,
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              Kişisel verilerinin veri sorumlusu sıfatıyla Şirket veya gerekli
              güvenlik tedbirlerini aldırmak suretiyle yetkilendirdiği veri
              işleyenler tarafından; Şirket’in müşterilerine sunmuş olduğu ürün
              ve hizmetlerini en iyi koşullar altında sağlayabilmesi, ürün veya
              hizmetlerin güvenilir ve kesintisiz bir şekilde temin edilmesi,
              müşteri memnuniyetinin en üst seviyeye çıkarılması, ödemelerin
              yapılması, mezkûr hizmetlere ilişkin çeşitli işlemlerin yerine
              getirilmesi, operasyonların yürütülmesi ve geliştirilmesi, mezkûr
              ürün ve hizmetlerin veya farklı ürün ve hizmetlerin tanıtım,
              pazarlama, reklam ve kampanya faaliyetlerinin yapılması,
              müşterilerin fırsatlardan, kampanyalardan ve sair hizmetlerden
              haberdar edilmesi ve müşterilerle akdedilen sözleşmelerin
              gereklerinin yerine getirilmesi amaçlarıyla doğrudan veya dolaylı
              olarak ilgili olan kimlik bilgilerinin, adres bilgilerinin,
              iletişim bilgilerinin ve sair kişisel verilerin; başta mevzuatta
              öngörülen veya işlendikleri amaç için gerekli olan süre kadar
              muhafaza edilme ilkesi olmak üzere 6698 Sayılı Kişisel Verilerin
              Korunması Kanunu’nun (“KVK Kanunu”) 4. maddesinde ifade edilen
              genel ilkelere uygun şekilde işlenebileceğini; elde
              edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun süre
              zarfında fiziksel veya elektronik ortamda güvenli bir şekilde
              depolanabileceğini, muhafaza edilebileceğini,
              değiştirilebileceğini, yeniden düzenlenebileceğini, mevzuata uygun
              biçimde açıklanabileceğini ve aktarılabileceğini,
              devralınabileceğini, sınıflandırılabileceğini, işlenebileceğini ya
              da verilerin kullanılmasının engellenebileceğini; yukarıda
              belirtilen hususlarla ilgili olarak Şirket tarafından
              bilgilendirildiğimi ve KVK Kanunu çerçevesinde açık rızam
              bulunduğunu kabul ve beyan ederim.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              İşbu kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı
              kalmak kaydıyla, Şirket tarafından; Şirket çalışanlarına,
              görevlilerine, grup şirketlerine (Şirket ve/veya iş ortaklarına,
              hissedarlarına), kanunen yetkili kamu kurum ve kuruluşlarına,
              faaliyetlerini yürütebilmek amacıyla, hukuki zorunluluklar ve
              yasal sınırlamalar çerçevesinde bağımsız denetim şirketlerine,
              anket şirketlerine, tarafıma verilecek hizmetlerin ve/veya
              faaliyetlerin yürütülmesi için Şirket’in hizmet aldığı veya
              birlikte çalıştığı iş ortaklarına ve hizmet sağlayıcılarına
              aktarılabileceğini ve bu hususta açık rızam olduğunu kabul ve
              beyan ederim.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              Bununla birlikte, KVK Kanunu’nun 11.maddesi ve ilgili mevzuat
              uyarınca; Şirkete başvurarak kendimle ilgili; kişisel veri işlenip
              işlenmediğini öğrenme, kişisel verilerim işlenmişse buna ilişkin
              bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların
              amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde
              veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri
              bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme, işbu verilerin işlenmesini
              gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin
              silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme
              taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere
              bildirilmesini isteme, işlenen verilerin münhasıran otomatik
              sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir
              sonucun ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna
              aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
              giderilmesini talep etme haklarımın olduğunu ve bu hakları
              kullanmak için kimliğimi tespit edici gerekli bilgiler ile
              kullanmayı talep ettiğim hakkıma yönelik açıklamaları da içeren
              talebimi www.terratrade.be adresindeki formu doldurarak Şirket
              Müdürlüğü’ne kimliğimi tespit edici belgeler ile bizzat elden
              iletme yahut noter kanalıyla veya KVK Kanunu’nda belirtilen diğer
              yöntemler ile iletme hakkına sahip olduğumu kabul ediyorum.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              Ayrıca, Şirket ile paylaşmış olduğum kişisel verilerin doğru ve
              güncel olduğunu; işbu bilgilerde değişiklik olması halinde
              değişiklikleri Şirkete bildireceğimi kabul ve beyan ederim.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              KVK Kanunu’nda tanımlanan özel nitelikli kişisel verilerim de
              dahil olmak üzere ilgili kişisel verilerimin işlenmesine, ilgili
              süreç kapsamında işlenme amacı ile sınırlı olmak üzere
              kullanılmasına ve paylaşılmasına, gereken süre zarfında
              saklanmasına açık rızam olduğunu ve bu hususta tarafıma gerekli
              aydınlatmanın yapıldığını; işbu metni, Şirket Kişisel Verilerin
              Korunması ve İşlenmesi Yönetmeliğini ve Aydınlatma Metnini
              okuduğumu ve anladığımı onaylıyorum.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant={smsize ? 'h3' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              PRIVACY POLICY
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              In Article 3 of KVK Law No. 6698; Explicit consent is defined in
              the law as “consent on a specific subject, based on information
              and expressed with free will”. Accordingly, explicit consent has
              three elements:
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              • Being related to a specific subject
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              • Based on information
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              • Disclosure of free will.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              Terra Trade SRL EXPLAINED DECLARATION OF CONSENT UNDER THE LAW ON
              PROTECTION OF PERSONAL DATA Terra Trade SRL.(“Company”), in
              accordance with the relevant provisions of the Personal Data
              Protection Law No.6698 (“KVK Law”), within the framework of the
              General Disclosure Text within the scope of the Personal Data
              Protection Law and the Company Personal Data Protection and
              Processing Regulation,
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              By the Company as the data controller of their personal data or by
              data processors authorized by taking the necessary security
              measures; The Company’s ability to provide the products and
              services offered to its customers under the best conditions, to
              provide the products or services in a reliable and uninterrupted
              manner, to maximize customer satisfaction, to make payments, to
              perform various transactions related to the aforementioned
              services, to carry out and develop operations, aforementioned
              Identity information, address information, contact information and
              information directly or indirectly related to the promotion,
              marketing, advertising and campaign activities of products and
              services or different products and services, to inform customers
              about opportunities, campaigns and other services, and to fulfill
              the requirements of contracts concluded with customers. other
              personal data; It can be processed in accordance with the general
              principles stated in Article 4 of the Personal Data Protection Law
              No.6698 (“KVK Law”), especially the principle of preserving for
              the period stipulated in the legislation or for the purpose for
              which they are processed; It can be obtained, recorded, stored,
              stored, changed, reorganized, disclosed and transferred in
              accordance with the legislation, taken over, classified, processed
              or prevented from the use of data in a physical or electronic
              environment for the purpose of processing; I accept and declare
              that I have been informed by the Company about the above-mentioned
              issues and that I have express consent within the framework of the
              KVK Law.
            </Typography>
            <Typography
              variant={smsize ? 'h6' : 'body1'}
              sx={{ mt: 2 }}
              color={'black'}
            >
              However, in accordance with Article 11 of the KVK Law and the
              relevant legislation; About myself by applying to the company; To
              learn whether personal data is processed, to request information
              if my personal data has been processed, to learn the purpose of
              processing my personal data and whether they are used for their
              purpose, to know the third parties to whom personal data is
              transferred in the country or abroad, to request correction of
              personal data in case of incomplete or incorrect processing To
              request the deletion or destruction of my personal data in the
              event that the reasons requiring the processing of this data
              disappear, to request that these correction and deletion requests
              be notified to third parties to whom personal data is transferred,
              to object to the occurrence of a result against you by analyzing
              the processed data exclusively through automated systems, I have
              the right to demand the compensation of the damage in case of
              damage due to unlawful processing and the right to use my identity
              with the necessary information to use these rights I fill out the
              form at www.terratrade.be and I accept that I have the right to
              forward my identity documents to the Company Directorate at the
              address of by hand or through a notary public or other methods
              specified in the KVK Law. I have explicit consent to the
              processing of my personal data, including my personal data of
              special nature defined in the KVK Law, to its use and sharing
              limited to the purpose of processing within the scope of the
              relevant process, to its storage within the required period of
              time and that the necessary enlightenment has been given to me in
              this regard; I agree that I have read and understood this text,
              the Company’s Personal Data Protection and Processing Regulation
              and the Clarification Text.
            </Typography>
          </>
        )}
      </Box>
    </div>
  );
}

export default PrivacyPolicy;
