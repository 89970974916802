import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'mui-image';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';

function AboutUs() {
  let { lang } = useParams();
  const theme = useTheme();
  const smsize = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    console.log(lang);
  }, [lang]);
  //columnSpacing={10} rowSpacing={10} paddingX={10} marginY={-3}
  return (
    <div>
      <TopBar language={lang}></TopBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          height: { sm: '40vh' },
          overflow: 'hidden',
        }}
      >
        <Image
          src={'/images/aboutus.jpg'}
          fit="cover"
          style={{ position: 'relative', objectPosition: 'top' }}
        ></Image>
        <Typography
          variant={smsize ? 'h1' : 'h4'}
          position={'absolute'}
          color={'white'}
          fontWeight={'bold'}
        >
          {lang ? 'BİZ KİMİZ?' : 'ABOUT US'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 8,
          width: { xs: '80%', sm: '50%' },
          mx: 'auto',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h6'}
          color={'black'}
          fontWeight={'bold'}
        >
          {lang
            ? 'AKDENIZ’DEN AVRUPA’NIN KALBINE..'
            : 'FROM MEDITERRANEAN TO THE HEART OF EUROPE.'}
        </Typography>
        <Typography
          variant={smsize ? 'h6' : 'h6'}
          sx={{ mt: 8 }}
          color={'black'}
        >
          {lang
            ? 'Terra Trade, Belçika ve Türkiye’de ofisleri bulunan, uluslararası bir otomotiv, tekstil ve gıda ürünleri tedarikçisidir.'
            : 'Terra Trade is an international supplier of automotive, textile and food products with offices in Belgium and Turkey. We appreciate the importance of strong lines of communication and high-touch relationships.'}
        </Typography>
        <Typography
          variant={smsize ? 'h6' : 'h6'}
          sx={{ mt: 8 }}
          color={'black'}
        >
          {lang
            ? 'Terra Trade SRL, Brüksel Başkent Bölgesi’ne kayıtlı bir Belçika şirketidir. Ürün gamındaki tüm ürünleri Avrupa içinde, müşteri ihtiyaçları doğrultusunda gümrük işlemleri gerçekleştirilmiş olarak sunar.'
            : 'Terra Trade SRL is a Belgian company registered in Brussels Capital Region. We offer all products in our range as customs cleared in Europe in line with your requirements.'}
        </Typography>
        <Typography
          variant={smsize ? 'h6' : 'h6'}
          sx={{ mt: 8 }}
          color={'black'}
        >
          {lang
            ? 'Terra Trade LTD, merkezi Ege Bölgesi’nin incisi İzmir’ de bulunan ve bu sayede kaynaklarıyla çok yakın ilişkiler içinde olan bir Türk şirketidir.'
            : 'Terra Trade LTD is a Turkish company registered in İzmir within Mediterranean, which provides us building strong rapports with our sources.'}
        </Typography>
        <Typography
          variant={smsize ? 'h6' : 'h6'}
          sx={{ mt: 8 }}
          color={'black'}
        >
          {lang
            ? 'Terra Trade 2 alanda faaliyet göstermektedir: otomotiv ve gıda'
            : 'Terra Trade consists of 2 divisions: automotive and food.'}
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={4}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 8,
          mb: 10,
          width: '80%',
          mx: 'auto',
        }}
      >
        <Grid sm={6}>
          <Typography
            variant={smsize ? 'h4' : 'h6'}
            color={'black'}
            fontWeight={'bold'}
          >
            {lang ? 'MISYON' : 'MISSION'}
          </Typography>
          <Typography
            variant={smsize ? 'h6' : 'h6'}
            sx={{ mt: 2 }}
            color={'black'}
          >
            {lang
              ? 'En güvenilir ve dinamik tedarikçilerden biri olmak'
              : 'To be one of the most reliable and agile suppliers'}
          </Typography>
        </Grid>
        <Grid sm={6}>
          <Typography
            variant={smsize ? 'h4' : 'h6'}
            color={'black'}
            fontWeight={'bold'}
          >
            {lang ? 'VIZYON' : 'VISION'}
          </Typography>
          <Typography
            variant={smsize ? 'h6' : 'h6'}
            sx={{ mt: 2 }}
            color={'black'}
          >
            {lang
              ? 'Kalite-fiyat dengesini gözeterek geniş ürün yelpazesi ile müşteri beklentilerini sürdürülebilir şekilde karşılamak.'
              : 'To best meet customer expectations in a sustainable way with a wide range of products by considering price-quality balance.'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;
