function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
}

function AddressMap({ tr }) {
  const BEmap =
    '<div style="width: 100%"><iframe width="100%" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Rue%20Dodon%C3%A9e%2075B,%201180%20Uccle,%20Bel%C3%A7ika+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div>';
  const TRmap =
    '<div style="width: 100%"><iframe width="100%" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1572.%20Sk.%20No:33%20%C3%87%C4%B1narl%C4%B1%2035170%20Konak/%C4%B0zmir+(asd)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div>';
  return (
    <div>
      <Iframe iframe={tr ? TRmap : BEmap} />,
    </div>
  );
}

export default AddressMap;
