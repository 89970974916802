import { Box, SvgIcon, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as EnvelopeIcon } from 'icons/envelope-regular.svg';
import { ReactComponent as LocationIcon } from 'icons/location-dot-solid.svg';
import { ReactComponent as PhoneIcon } from 'icons/phone-solid.svg';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddressMap from '../components/AddressMap';
import TopBar from '../components/TopBar';

function Contact() {
  let { lang } = useParams();
  const theme = useTheme();
  const smsize = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    console.log(lang);
  }, [lang]);
  return (
    <div>
      <TopBar language={lang}></TopBar>
      <Grid container sx={{ width: { sm: '80%' }, mx: 'auto' }}>
        <Grid sm={6}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              textAlign: 'center',
              ml: { xs: 3, sm: 8 },
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              TERRA TRADE SRL
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <PhoneIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                +32 460 963955
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <EnvelopeIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                INFO@TERRATRADE.BE
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <LocationIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                RUE DODONÉE 75 B, 1180 UCCLE BRUSSELS BELGIUM
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid sm={6} sx={{ width: '100%' }}>
          <Box sx={{ mx: 'auto', width: '90%', mt: { xs: 4, sm: 0 } }}>
            <AddressMap tr={false}></AddressMap>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ width: { sm: '80%' }, mx: 'auto' }}>
        <Grid sm={6}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              textAlign: 'center',
              ml: { xs: 3, sm: 8 },
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              TERRA TRADE LTD
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <PhoneIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                +90 232 5206402
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <EnvelopeIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                INFO@TERRATRADE.BE
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                mt: 4,
              }}
            >
              <SvgIcon sx={{ color: 'blue', fontSize: 15, mr: 1 }}>
                <LocationIcon />
              </SvgIcon>
              <Typography
                variant={smsize ? 'body1' : 'caption'}
                color={'black'}
                fontWeight={'bold'}
              >
                INARLI MH. 1572 SK. NO:33 KONAK İZMİR TURKEY
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid sm={6} sx={{ width: '100%' }}>
          <Box sx={{ mx: 'auto', width: '90%', mt: { xs: 4, sm: 0 } }}>
            <AddressMap tr={true}></AddressMap>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contact;
