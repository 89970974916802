import { Box, Button, SvgIcon, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as DeafIcon } from 'icons/ear-deaf-solid.svg';
import { ReactComponent as EnviraIcon } from 'icons/envira.svg';
import { ReactComponent as AtomIcon } from 'icons/react.svg';

import Image from 'mui-image';
import { useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';

function Automotive() {
  let { lang } = useParams();
  const theme = useTheme();
  const smsize = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box bgcolor="#ebf4f3">
      <TopBar language={lang}></TopBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          height: { xs: '30vh', sm: '60vh' },
          overflow: 'hidden',
          mx: { sm: '10%' },
        }}
        style={{
          borderRadius: '2rem',
          boxShadow: '10px 10px 25px #000000',
        }}
      >
        <Image
          src={'/images/tire-banner-large.png'}
          fit="cover"
          style={{
            position: 'relative',
            objectPosition: 'top',
          }}
        ></Image>
        <Box
          position={'absolute'}
          sx={{ left: '15%', top: { xs: '25%', sm: '30%' } }}
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant={smsize ? 'h1' : 'h5'}
            color={'white'}
            fontWeight={'bold'}
          >
            {'Waterfall'}
          </Typography>
          <Typography variant={smsize ? 'h4' : 'body1'} color={'white'}>
            {lang
              ? 'Her Şartlarda Yüksek Performans'
              : 'High Performance in every condition'}
          </Typography>

          <Button
            sx={{ width: { xs: '60%', sm: '40%' }, mt: 5 }}
            href={
              lang
                ? 'https://waterfalltyres.com/tr'
                : 'https://waterfalltyres.com/en'
            }
            variant="outlined"
            style={{ color: 'white', borderColor: 'white' }}
          >
            {lang ? 'Şimdi Keşfet' : 'Discover Now'}
          </Button>
        </Box>
      </Box>
      <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang
                ? 'WATERFALL KALITESINI HISSET'
                : 'FEEL THE QUALITY WITH WATERFALL TYRES'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade her marka ve ebatta binek, SUV / 4X4, hafif ticari, kamyon, tır ve otobüs lastiklerinin uluslararası tedarikçisidir.'
                : 'Terra Trade is an authorized supplier of Waterfall Tyres, made in Turkey. Waterfall has full compliance with legislation of EU.'}
            </Typography>
            {lang ? (
              <Typography
                variant={smsize ? 'body1' : 'body2'}
                color={'black'}
                fontWeight={'bold'}
                sx={{ marginTop: 4 }}
              >
                Şirket AB mevzuatıyla tam uyumlu olan ve Türkiye’de üretilen
                Waterfall lastiklerinin yetkili temsilcisidir.
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
        <Grid md={6} sx={{ maxHeight: { sm: '60vh' } }}>
          <Box sx={{ mx: 'auto', width: '70%' }}>
            <Image
              src={'/images/quattro-wide.png'}
              width={'100%'}
              // height={'80%'}
              fit="fill"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto', mt: 4 }}
        spacing={4}
      >
        <Grid xs={12} sx={{ mb: 4 }} textAlign="center">
          <Typography
            variant={smsize ? 'h4' : 'h4'}
            color={'black'}
            fontWeight={'bold'}
          >
            {lang ? 'NEDEN WATERFALL?' : 'WHY WATERFALL'}
          </Typography>
        </Grid>

        <Grid sm={4} textAlign="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <SvgIcon sx={{ color: 'blue', fontSize: 40, mb: 2 }}>
              <AtomIcon></AtomIcon>
            </SvgIcon>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang
                ? 'AKILLI BİLEŞİK TEKNOLOJİSİ'
                : 'INTELLIGENT COMPOUND TECH'}
            </Typography>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight="bold"
              sx={{ mt: 2 }}
            >
              {lang
                ? 'Değişken hava koşullarına göre en iyi performansı sağlar'
                : 'Provide best performance according to changable weather conditions'}
            </Typography>
          </Box>
        </Grid>

        <Grid sm={4} textAlign="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <SvgIcon sx={{ color: 'blue', fontSize: 40, mb: 2 }}>
              <DeafIcon></DeafIcon>
            </SvgIcon>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'SESSİZ VE RAHAT' : 'QUIET AND COMFORTABLE'}
            </Typography>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight="bold"
              sx={{ mt: 2 }}
            >
              {lang
                ? 'Waterfall lastiklerinin gürültü seviyesi çok düşüktür'
                : 'Waterfall tires have very low noise level'}
            </Typography>
          </Box>
        </Grid>

        <Grid sm={4} textAlign="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <SvgIcon sx={{ color: 'blue', fontSize: 40, mb: 2 }}>
              <EnviraIcon></EnviraIcon>
            </SvgIcon>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'EKO LASTİK TEKNOLOJİSİ' : 'ECO TIRE TECH'}
            </Typography>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'black'}
              fontWeight="bold"
              sx={{ mt: 2 }}
            >
              {lang
                ? 'Daha hafif lastikler ve özel bileşikler daha düşük yakıt maliyetleri ve CO2 emisyonu sağlar'
                : 'Lighter tires and special compounds provide lower fuel costs and CO2 emissions'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          my: 4,
          width: '80%',
          mx: 'auto',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h4'}
          color={'black'}
          fontWeight={'bold'}
        >
          {lang ? 'NEDEN TERRA TRADE?' : 'WHY TERRA TRADE?'}
        </Typography>
        <Typography
          variant={smsize ? 'h5' : 'h5'}
          color={'black'}
          fontWeight="bold"
          sx={{ mt: 4 }}
        >
          {lang
            ? 'Ana markası Waterfall olan Terra Trade, hem ekonomik hem de premium fiyat segmentinde yer alan  birçok markayı ürün gamında bulundurmaktadır.'
            : 'Terra Trade is an international wholesaler of passenger, SUV / 4×4, van, truck, and bus tyres in any sizes and focuses on both high-end and budget commercial and consumer product categories. The company, whose main brand is Waterfall, supplies also well-known premium brands.'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 10,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Image
          src={'/images/waterfall-logo.png'}
          fit="contain"
          style={{ width: '300px', height: '121px' }}
        ></Image>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 2,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Box sx={{ width: '80%' }}>
          <Grid container spacing={2}>
            <Grid sm={3}>
              <Image src={'/images/eco-dynamic.png'} fit="contain"></Image>
            </Grid>
            <Grid sm={3}>
              <Image src={'/images/snow-hill.png'} fit="contain"></Image>
            </Grid>
            <Grid sm={3}>
              <Image src={'/images/quattro.png'} fit="contain"></Image>
            </Grid>
            <Grid sm={3}>
              <Image src={'/images/lt-200.png'} fit="contain"></Image>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Automotive;
