import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import AboutUs from './pages/Aboutus';
import Automotive from './pages/Automotive';
import Contact from './pages/Contact';
import Food from './pages/Food';
import MainPage from './pages/MainPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import reportWebVitals from './reportWebVitals';

const innerRoutes = (
  <Route>
    <Route index element={<MainPage />} />
    <Route path="automotive" element={<Automotive />} />
    <Route path="food" element={<Food />} />
    <Route path="about" element={<AboutUs />} />
    <Route path="contact" element={<Contact />} />
    <Route path="privacy-policy" element={<PrivacyPolicy />} />

  </Route>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {innerRoutes}
          <Route path=":lang">
            {innerRoutes}
          </Route>
        </Route>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
