import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'mui-image';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';

function MainPage() {
  let { lang } = useParams();
  const theme = useTheme();
  const smsize = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    console.log(lang);
  }, [lang]);
  //columnSpacing={10} rowSpacing={10} paddingX={10} marginY={-3}
  return (
    <div>
      <TopBar language={lang}></TopBar>
      {smsize ? (
        <>
          <Box
            sx={{
              backgroundImage: "url('/images/eng/tire-background.png')",
              backgroundSize: '100%',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              opacity: '0.2',
              zIndex: '-1',
              backgroundRepeat: 'no-repeat',
            }}
            style={{ clipPath: 'polygon(100% 0, 0 100%, 0 0)' }}
          ></Box>
          <Box
            sx={{
              backgroundImage: "url('/images/eng/food-background.png')",
              backgroundSize: '100%',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              opacity: '0.2',
              zIndex: '-1',
              backgroundRepeat: 'no-repeat',
            }}
            style={{
              clipPath: 'polygon(100% 100%, 0 100%, 100% 0)',
              backgroundPositionY: '50%',
            }}
          ></Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              backgroundImage: "url('/images/eng/tire-background.png')",
              backgroundSize: '100%',
              width: '100%',
              height: '50%',
              position: 'absolute',
              top: '0',
              left: '0',
              opacity: '0.2',
              zIndex: '-1',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>
          <Box
            sx={{
              backgroundImage: "url('/images/eng/food-background.png')",
              backgroundSize: '100%',
              width: '100%',
              height: '50%',
              position: 'absolute',
              bottom: '0',
              left: '0',
              opacity: '0.2',
              zIndex: '-1',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>
        </>
      )}

      <Grid
        container
        sx={{ paddingX: '5%', paddingY: '' }}
        columnSpacing={10}
        rowSpacing={10}
      >
        <Grid item md={6} sx={{}}>
          <Box sx={{ width: '95%', '&:hover': { width: '100%' } }}>
            <Box
              component={Link}
              to={lang ? `/${lang}/automotive` : '/automotive'}
            >
              <Image
                src={
                  lang
                    ? '/images/tr/tire-mobile-tr.png'
                    : '/images/eng/tire-mobile-en.png'
                }
                shift="right"
                shiftDuration={1500}
                fit="fill"
                style={{
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 25px #000000',
                }}
              ></Image>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ marginTop: { md: '15%' } }}>
          <Box sx={{ width: '95%', '&:hover': { width: '100%' } }}>
            <Box component={Link} to={lang ? `/${lang}/food` : '/food'}>
              <Image
                src={
                  lang
                    ? '/images/tr/food-mobile-tr.png'
                    : '/images/eng/food-mobile-en.png'
                }
                shift="left"
                shiftDuration={1500}
                fit="fill"
                style={{
                  borderRadius: '2rem',
                  boxShadow: '10px 10px 25px #000000',
                }}
              ></Image>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default MainPage;
