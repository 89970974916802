import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'mui-image';
import { useParams } from 'react-router-dom';
import TopBar from '../components/TopBar';

function Food() {
  let { lang } = useParams();
  const theme = useTheme();

  const smsize = useMediaQuery(theme.breakpoints.up('sm'));

  const trFruitNames1 = [
    'İncir',
    'Çilek',
    'Frambuaz',
    'Yaban mersini',
    'Karadut',
    'Kiraz',
  ];
  const engFruitNames1 = [
    'Fig',
    'Strawberry',
    'Raspberry',
    'Blueberry',
    'Black Mulberry',
    'Cherry',
    'Lime',
  ];
  const fruitNames1 = lang ? trFruitNames1 : engFruitNames1;

  const trFruitNames2 = ['Limon', 'Portakal', 'Elma', 'Muz', 'Ananas', 'Mango'];
  const engFruitNames2 = [
    'Lemon',
    'Orange',
    'Apple',
    'Banana',
    'Pineapple',
    'Mango',
  ];
  const fruitNames2 = lang ? trFruitNames2 : engFruitNames2;

  const trFruitNames3 = ['Kivi', 'Nar', 'Mantar', 'Biber', 'Mısır', 'Bezelye'];
  const engFruitNames3 = [
    'Kiwi',
    'Pomegranate',
    'Mushroom',
    'Pepper',
    'Corn',
    'Peas',
  ];
  const fruitNames3 = lang ? trFruitNames3 : engFruitNames3;

  return (
    <Box bgcolor="#ebf4f3">
      <TopBar language={lang}></TopBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          height: { xs: '30vh', sm: '60vh' },
          overflow: 'hidden',
          mx: { sm: '10%' },
        }}
        style={{
          borderRadius: '2rem',
          boxShadow: '10px 10px 25px #000000',
        }}
      >
        <Image
          src={'/images/mediterrany-wide.jpg'}
          fit="cover"
          style={{
            position: 'relative',
            objectPosition: 'center',
            filter: 'brightness(70%)',
          }}
        ></Image>
        <Box
          position={'absolute'}
          sx={{ left: '15%', top: { xs: '25%', sm: '30%' } }}
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant={smsize ? 'h1' : 'h5'}
            color={'white'}
            fontWeight={'bold'}
          >
            {'Mediterrany'}
          </Typography>
          <Typography variant={smsize ? 'h4' : 'body1'} color={'white'}>
            {lang
              ? 'Meyvenin en lezzetli hali'
              : 'The Tastiest Version of Fruit'}
          </Typography>

          <Button
            sx={{ width: { xs: '60%', sm: '40%' }, mt: 5 }}
            href={
              lang
                ? 'https://www.mediterrany.eu/tr/'
                : 'https://www.mediterrany.eu/en/'
            }
            variant="outlined"
            style={{ color: 'white', borderColor: 'white' }}
          >
            {lang ? 'Şimdi Keşfet' : 'Discover Now'}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80%',
          textAlign: 'center',
          mx: 'auto',
          my: 4,
        }}
      >
        <Typography
          variant={smsize ? 'body1' : 'body2'}
          color={'black'}
          sx={{ marginTop: 4 }}
        >
          {lang
            ? 'Terra Trade, tamamı Türkiye’de üretilen yağlı tohum, kabuklu yemiş, baharat, zeytinyağı, kuru meyve, dondurulmuş kurutulmuş meyve ve sebze alanlarında uzmanlaşmış uluslararası bir gıda ürünleri tedarikçisidir.'
            : 'Terra Trade is an international supplier with offices in Belgium and Turkey, expertized in exporting seeds, herbs, olive oil, nuts, dried and freeze-dried fruits, and vegetables. The company offers a large and diverse range of products originated from Turkey.'}
        </Typography>
      </Box>

      <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang
                ? 'DONDURULMUS KURUTULMUS MEYVE VE SEBZELER'
                : 'FREEZE DRIED FRUITS AND VEGETABLES'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, doğal aromalarını  ve besin değerlerini tamamen koruyan, dondurularak kurutulmuş meyve ve sebzelerin uluslararası tedarikçisidir.'
                : 'Terra Trade offers freeze dried fruits and vegetables which preserve all-natural flavors and nutrition facts.'}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                justifyContent: 'space-between',
                textAlign: 'center',
                width: { xs: '100%', lg: '80%' },
              }}
            >
              <Box>
                {fruitNames1.map((value) => (
                  <Typography
                    variant={smsize ? 'body1' : 'body2'}
                    color={'black'}
                    sx={{ marginTop: 4 }}
                  >
                    {value}
                  </Typography>
                ))}
              </Box>
              <Box>
                {fruitNames2.map((value) => (
                  <Typography
                    variant={smsize ? 'body1' : 'body2'}
                    color={'black'}
                    sx={{ marginTop: 4 }}
                  >
                    {value}
                  </Typography>
                ))}
              </Box>
              <Box>
                {fruitNames3.map((value) => (
                  <Typography
                    variant={smsize ? 'body1' : 'body2'}
                    color={'black'}
                    sx={{ marginTop: 4 }}
                  >
                    {value}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Dondurularak kurutulmuş tüm ürün yelpazesini ve ambalaj seçeneklerini öğrenmek için lütfen bizimle iletişime geçiniz.'
                : 'Please contact us to get further information regarding the entire freeze dried product range.'}
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{ width: '100%' }}
            overflow={'hidden'}
            style={{
              borderRadius: '2rem',
              boxShadow: '10px 10px 25px #000000',
            }}
          >
            <Image
              src={'/images/freeze-dried-fruits.jpg'}
              fit="fill"
              width={'100%'}
            ></Image>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 10,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h5'}
          color={'white'}
          fontWeight={'bold'}
        >
          {lang ? 'ÜRÜNLER' : 'PRODUCTS RANGE'}
        </Typography>
        <Grid container sx={{ width: '80%', mt: 4, mx: 'auto' }} spacing={4}>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/freeze-dried-lemon.png'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'FREEZE-DRIED LİMON' : 'FREEZE-DRIED LEMON'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/freeze-dried-mango.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'FREEZE-DRIED MANGO' : 'FREEZE-DRIED MANGO'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/freeze-dry-raspberry.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'FREEZE-DRIED AHUDUDU' : 'FREEZE-DRIED RASPBERRY'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/freeze-dry-strawberry.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'FREEZE-DRIED ÇİLEK' : 'FREEZE-DRIED STRAWBERRY'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'YAGLI TOHUMLAR' : 'SEEDS'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, dünya genelinde değirmencilik ve fırıncılık gibi endüstrilere çeşitli tohumlar tedarik etmektedir.'
                : 'Terra Trade supplies a wide range of seeds to the diverse industries such as milling and bakery across the world.'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Haşhaş – Mavi & Beyaz' : 'Poppy Seeds – Blue & White'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Susam' : 'Sesame Seeds – White & Black & Golden (Brown)'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Ayçekirdeği' : 'Sunflower Seeds'}
            </Typography>

            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Ambalaj' : 'Packaging'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '10 kg kraft torba' : '10 kg kraft bag'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '11 kg kraft torba' : '11 kg kraft bag'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '12.5 kg kraft torba' : '12.5 kg kraft bag'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '25 kg kraft torba' : '25 kg kraft bag'}
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{ width: '100%', height: '60vh' }}
            overflow={'hidden'}
            style={{
              borderRadius: '2rem',
              boxShadow: '10px 10px 25px #000000',
            }}
          >
            <Image src={'/images/seeds-main.jpg'} fit="cover"></Image>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 10,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h5'}
          color={'white'}
          fontWeight={'bold'}
        >
          {lang ? 'ÜRÜNLER' : 'PRODUCTS RANGE'}
        </Typography>
        <Grid container sx={{ width: '80%', mt: 4, mx: 'auto' }} spacing={4}>
          <Grid
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/hashas.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'HAŞHAŞ' : 'POPPY SEEDS'}
            </Typography>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/susam.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'SUSAM' : 'SESAME SEEDS'}
            </Typography>
          </Grid>
          <Grid
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/sunflower-seed.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'AYÇİÇEĞİ' : 'SUNFLOWER SEEDS'}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'BITKI VE BAHARATLAR' : 'HERBS & SPICES'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, en yüksek kaliteyi sağlamak için özenle seçilmiş Türk menşeli bitki ve baharatları sunmaktadır.'
                : 'Terra Trade offers Turkish origin herbs and spices which are carefully selected to ensure premium quality.'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Kekik' : 'Oregano'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Kimyon' : 'Thyme'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Anason' : 'Cumin Seed'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Dağ Kekiği' : 'Anise Seed'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Ambalaj' : 'Packaging'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '12.5 kg kraft torba' : '12.5 kg kraft bag'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? '25 kg kraft torba' : '25 kg kraft bag'}
            </Typography>
          </Box>
        </Grid>
        <Grid md={6} overflow={'hidden'}>
          <Box sx={{ width: '100%', height: 'full' }}>
            <Image src={'/images/spices-main.jpg'} fit="fill"></Image>
          </Box>
        </Grid>
      </Grid> */}

      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 10,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h5'}
          color={'white'}
          fontWeight={'bold'}
        >
          {lang ? 'ÜRÜNLER' : 'PRODUCTS RANGE'}
        </Typography>
        <Grid container sx={{ width: '80%', mt: 4, mx: 'auto' }} spacing={4}>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src={'/images/thyme.jpg'} fit="contain"></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'DAĞ KEKİĞİ' : 'THYME'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src={'/images/oregano.jpg'} fit="contain"></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'KEKİK' : 'OREGANO'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src={'/images/cumin-seed.jpg'} fit="contain"></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'KİMYON' : 'CUMIN SEED'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src={'/images/anise-seed.jpg'} fit="contain"></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'ANASON' : 'ANISE SEED'}
            </Typography>
          </Grid>
        </Grid>
      </Box> */}

      {/* <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'ZEYTINYAGI' : 'OLIVE OIL'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, üstün kalitesi ve yüksek polifenol içeriği ile tanınan Türk menşeli soğuk sıkım sızma zeytinyağı tedarikçisidir.'
                : 'Terra Trade is a supplier of Turkish origin cold-extracted extra virgin olive oils which acknowledged for its premium quality and high polyphenol content.'}
            </Typography>

            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Ambalaj' : 'Packaging'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, zeytinyağlarını müşteri ihtiyaçları doğrultusunda hem dökme hem de bireysel ambalajlarda tedarik etmektedir.'
                : 'We supply olive oils both bulk and individual packaging in line with your requirements.'}
            </Typography>
          </Box>
        </Grid>
        <Grid md={6} overflow={'hidden'}>
          <Box sx={{ width: '100%', height: 'full' }}>
            <Image src={'/images/oliveoil.jpg'} fit="fill"></Image>
          </Box>
        </Grid>
      </Grid> */}
      <Grid
        container
        sx={{ paddingY: '4%', width: '80%', mx: 'auto' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Grid md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant={smsize ? 'h4' : 'h5'}
              color={'black'}
              fontWeight={'bold'}
            >
              {lang ? 'KURU MEYVE' : 'DRIED FRUITS'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              fontWeight={'bold'}
              sx={{ marginTop: 4 }}
            >
              {lang
                ? 'Terra Trade, dünyada söz sahibi Türk kuru meyvelerinin uluslararası tedarikçisidir.'
                : 'Terra Trade is an international supplier of Turkish products that have a voice in the world in dried fruit environment.'}
            </Typography>

            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Kuru İncir' : 'Dried Fig '}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Kuru Kayısı' : 'Dried Apricot '}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Gün Kurusu Kayısı' : 'Sun Dried Apricot'}
            </Typography>
            <Typography
              variant={smsize ? 'body1' : 'body2'}
              color={'black'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'Kuru Üzüm' : 'Raisins '}
            </Typography>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box
            sx={{ width: '100%' }}
            overflow={'hidden'}
            style={{
              borderRadius: '2rem',
              boxShadow: '10px 10px 25px #000000',
            }}
          >
            <Image src={'/images/dried-fruit.jpg'} fit="fill"></Image>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'full',
          py: 10,
          overflow: 'hidden',
          backgroundColor: '#111111',
        }}
      >
        <Typography
          variant={smsize ? 'h4' : 'h5'}
          color={'white'}
          fontWeight={'bold'}
        >
          {lang ? 'ÜRÜNLER' : 'PRODUCTS RANGE'}
        </Typography>
        <Grid container sx={{ width: '80%', mt: 4, mx: 'auto' }} spacing={4}>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/dried-fig.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'KURU İNCİR' : 'DRIED FIG'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/dried-apricot.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'GÜN KURUSU KAYISI' : 'SUN-DRIED APRICOT'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/dried-apricot-yellow.jpg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'KURU KAYISI' : 'DRIED APRICOT'}
            </Typography>
          </Grid>
          <Grid
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={'/images/dried-grape.jpeg'}
              fit="contain"
              style={{
                borderRadius: '2rem',
                boxShadow: '10px 10px 25px #000000',
              }}
            ></Image>
            <Typography
              variant={smsize ? 'h5' : 'h5'}
              color={'white'}
              sx={{ marginTop: 4 }}
            >
              {lang ? 'ÜZÜM' : 'RAISINS'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Food;
